import React, { useContext } from 'react'
import { Menu as UikitMenu } from '@evofinance9/uikit'
import { useWeb3React } from '@web3-react/core'
import VersionBar from 'components/VersionBar'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetLocalProfile from 'hooks/useGetLocalProfile'
import useAuth from 'hooks/useAuth'
import useGetCakeBusdLpPrice from 'utils/useGetCakeBusdLpPrice'
import links from './config'

const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakeBusdPrice = useGetCakeBusdLpPrice()
  const profile = useGetLocalProfile()

  return (
    <>
      <UikitMenu
        links={links}
        account={account as string}
        login={login}
        logout={logout}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={selectedLanguage?.code || ''}
        langs={allLanguages}
        setLang={setSelectedLanguage}
        cakePriceUsd={cakeBusdPrice}
        profile={profile}
        {...props}
      />
      <VersionBar />
    </>
  )
}

export default Menu

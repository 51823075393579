import { Interface } from '@ethersproject/abi'
import { abi as PRESALE_ABI } from './presale.json'
import { abi as DATETIME_ABI } from './datetime.json'
// 0xe0A5F731B4674666B7C86cf68eBeCf32e0DE7300
// 0xc7314569eA909633Ae6FDc2d12835705C5719620
// 0x82D5403805E9611DC73f13918f2662c8301d194f
// 0x5C3D232c7660C46faDc1938646E142284c211B89

const PRESALE_INTERFACE = new Interface(PRESALE_ABI)
const PRESALE_ADDRESS = '0x55A3612A72d5E4f0BFed7ea6185f9Ec05E62B992'

const DATETIME_ADDRESS = '0x669eB198EB31D318FeFc2B0F14167A0c2E5495f9'

export default PRESALE_INTERFACE
export { PRESALE_ABI, PRESALE_ADDRESS, DATETIME_ABI, DATETIME_ADDRESS }
